.inter600{
    font-family: "Inter", sans-serif;
    font-size: 17.5px;
    font-weight: 700;
    color: #1F1F1F;
}

.inter500{
    font-family: "Inter", sans-serif;
    font-size: 17.5px;
    font-weight:500;
    color: #1F1F1F;
}

.inter500-12{
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight:500;
}